import { Reducer, Dispatch } from 'react';
import { Action } from '@nimles/react-redux';
import { CultureState, SetupState } from './types';

const availableCultures = ['en', 'sv'];

const SAVE_SETUP = 'SAVE_SETUP';

const RESET_SETUP = 'RESET_SETUP';

export const saveSetup = (data: any) => {
  return {
    type: SAVE_SETUP,
    data,
  };
};

export const resetSetup = () => {
  return {
    type: RESET_SETUP,
  };
};

export const setupReducer: Reducer<SetupState, Action> = (
  state = { values: { address: {} } },
  action
) => {
  if (action.type === SAVE_SETUP) {
    return { values: { ...state.values, ...action.data } };
  }
  if (action.type === RESET_SETUP) {
    return { values: { address: {} } };
  }

  return { ...state };
};
