import { createStore, compose, combineReducers } from 'redux';
import {
  usersReducer,
  authReducer,
  organizationsReducer,
  currentUserReducer,
  publicTenantsReducer,
  publicProductGroupsReducer,
  publicProductsReducer,
  cartsReducer,
  publicOrganizationsReducer,
  ordersReducer,
  paymentsReducer,
  publicPaymentOptionsReducer,
  publicDeliveryOptionsReducer,
  publicPagesReducer,
  publicLayoutsReducer,
  publicPostsReducer,
  publicThemesReducer,
  relationshipsReducer,
  pipelinesReducer,
  filesReducer,
  realestatesReducer,
  projectsReducer,
  recordsReducer,
  Action,
  publicProductPropertiesReducer,
  quotesReducer,
  quoteRequestsReducer,
  publicCategoriesReducer,
  publicFilesReducer,
  vehiclesReducer,
  publicVehiclesReducer,
  publicPricelistsReducer,
} from '@nimles/react-redux';
import Middleware from './middleware';
import { RootState } from './types';
import { setupReducer } from './setup';
import { uiControlsReducer } from './uiControls';
import { geoReducer } from './geo';
import { draftReducer } from './draft';
import { grossPriceReducer } from './isGrossPrice';

const version = (state = 0) => {
  return state;
};

const combinedReducer = combineReducers<RootState>({
  //accounting
  records: recordsReducer,
  //ecommerce
  carts: cartsReducer,
  orders: ordersReducer,
  quotes: quotesReducer,
  quoteRequests: quoteRequestsReducer,
  publicDeliveryOptions: publicDeliveryOptionsReducer,
  publicPaymentOptions: publicPaymentOptionsReducer,
  //cms
  publicLayouts: publicLayoutsReducer,
  publicPages: publicPagesReducer,
  publicPosts: publicPostsReducer,
  publicThemes: publicThemesReducer,
  //crm
  relationships: relationshipsReducer,
  pipelines: pipelinesReducer,
  //identity
  auth: authReducer,
  currentUser: currentUserReducer,
  publicTenants: publicTenantsReducer,
  users: usersReducer,
  //media
  files: filesReducer,
  publicFiles: publicFilesReducer,
  //organizastion management
  organizations: organizationsReducer,
  publicOrganizations: publicOrganizationsReducer,
  publicCategories: publicCategoriesReducer,
  //pim
  publicProductGroups: publicProductGroupsReducer,
  publicProductProperties: publicProductPropertiesReducer,
  publicProducts: publicProductsReducer,
  publicPricelists: publicPricelistsReducer,
  //property management
  realEstates: realestatesReducer,
  vehicles: vehiclesReducer,
  publicVehicles: publicVehiclesReducer,
  //project management
  projects: projectsReducer,
  //only client
  setup: setupReducer,
  uiControls: uiControlsReducer,
  geo: geoReducer,
  draft: draftReducer,
  grossPrice: grossPriceReducer,
  version,
});

const reducer = (state: any, action: Action) => {
  if (action.type === 'SIGNOUT_USER') {
    const { tenantId, clientId } = state.auth;

    state = {
      publicTenants: state.publicTenants,
      publicOrganizations: state.publicOrganizations,
      publicProductGroups: state.publicProductGroups,
      publicProductProperties: state.publicProductProperties,
      publicProducts: state.publicProducts,
      publicPaymentOptions: state.publicPaymentOptions,
      publicDeliveryOptions: state.publicDeliveryOptions,
      publicPages: state.publicPages,
      publicLayouts: state.publicLayouts,
      publicThemes: state.publicThemes,
      publicPosts: state.publicPosts,
      auth: { tenantId, clientId },
      version: state.version,
    };
  }

  return combinedReducer(state, action);
};

// if you're using redux-thunk or other middlewares, add them here
const createStoreWithMiddleware = compose(Middleware)(createStore);

export const configureStore = (initialState = {}) => {
  return createStoreWithMiddleware(reducer, initialState);
};
