import { Reducer } from 'react';
import { Action } from '@nimles/react-redux';

export const ENABLE_DRAFT = 'ENABLE_DRAFT';

export const enableDraft = () => ({
  type: ENABLE_DRAFT,
  data: true,
});

export const draftReducer: Reducer<boolean, Action> = (
  state = false,
  action: Action
) => {
  switch (action.type) {
    case ENABLE_DRAFT:
      return true;
    default:
      return state;
  }
};
