import { Reducer, Dispatch } from 'react';
import { GeoState } from './types';
import { Action } from '@nimles/react-redux';

export const SET_USER_POSITION = 'SET_USER_POSITION';

const requestPostion = () =>
  new Promise<GeolocationCoordinates>((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(
      (position) => resolve(position.coords),
      (error) => reject(error)
    )
  );

export const requestUserPosition = (onlyIfAlreadyPermitted?: boolean) => async (
  dispatch: Dispatch<Action>,
  getState: () => any
) => {
  if (onlyIfAlreadyPermitted) {
    const permission = await navigator.permissions?.query({
      name: 'geolocation',
    });

    if (permission?.state !== 'granted') {
      return;
    }
  }

  const { latitude, longitude } = await requestPostion();

  const position = { latitude, longitude };

  if (latitude && longitude) {
    await dispatch({
      type: SET_USER_POSITION,
      data: position,
    });
  }

  return position;
};

export const getUserPosition = () => async (
  dispatch: Dispatch<Action>,
  getState: () => any
) => {
  const { auth } = getState();
  const { latitude, longitude } = await requestPostion();

  const position = { latitude, longitude };

  if (latitude && longitude) {
    await dispatch({
      type: SET_USER_POSITION,
      data: position,
    });
  }

  return position;
};

export const geoReducer: Reducer<GeoState, Action> = (
  state = {
    userPosition: null,
  },
  action: Action
) => {
  switch (action.type) {
    case SET_USER_POSITION:
      return { ...state, userPosition: action.data };
    default:
      return state;
  }
};
