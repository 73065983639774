import { configureStore } from '.';
import { RootState } from './types';

let lastSerialised;

interface InitStoreOptions {
  tenantId: string;
  clientId?: string;
  localStorageName?: string;
}

export const initStore = ({
  tenantId,
  clientId,
  localStorageName,
}: InitStoreOptions) => {
  const localStorageState =
    typeof window !== 'undefined' &&
    window.localStorage &&
    localStorageName &&
    window.localStorage.getItem(localStorageName);

  let initialState = localStorageState ? JSON.parse(localStorageState) : {};

  const currentVersion = 1;

  if (
    !initialState ||
    !initialState.version ||
    initialState.version < currentVersion
  ) {
    initialState = { version: currentVersion };
  }
  const store = configureStore({
    ...initialState,
    auth: {
      ...initialState.auth,
      clientId,
      tenantId,
    },
  });

  store.subscribe(() => {
    const {
      auth,
      currentUser,
      version,
      uiControls,
      grossPrice,
    }: RootState = store.getState();
    const serialized = JSON.stringify({
      auth,
      currentUser,
      version,
      uiControls,
      grossPrice,
    });
    if (!lastSerialised || lastSerialised !== serialized) {
      lastSerialised = serialized;
      localStorage.setItem(localStorageName, serialized);
    }
  });

  return store;
};
