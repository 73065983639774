// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-catalog-tsx": () => import("./../../../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-checkout-confirmation-tsx": () => import("./../../../src/pages/checkout/confirmation.tsx" /* webpackChunkName: "component---src-pages-checkout-confirmation-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-connect-tsx": () => import("./../../../src/pages/connect.tsx" /* webpackChunkName: "component---src-pages-connect-tsx" */),
  "component---src-pages-resetpassword-tsx": () => import("./../../../src/pages/resetpassword.tsx" /* webpackChunkName: "component---src-pages-resetpassword-tsx" */),
  "component---src-templates-category-tsx": () => import("./../../../src/templates/category.tsx" /* webpackChunkName: "component---src-templates-category-tsx" */),
  "component---src-templates-organization-tsx": () => import("./../../../src/templates/organization.tsx" /* webpackChunkName: "component---src-templates-organization-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */)
}

