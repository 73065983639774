import { Reducer } from 'react';
import { UIControlsState } from './types';
import { Action } from '@nimles/react-redux';
import {
  SHOW_SIDEBAR,
  SHOW_ASIDE,
  ENABLE_DARKMODE,
  MINIMIZE_SIDEBAR,
} from './actionTypes';

export const uiControlsReducer: Reducer<UIControlsState, Action> = (
  state = {
    sidebarShow: true,
    sidebarMinimize: false,
    asideShow: false,
    darkMode: false,
  },
  action: Action
) => {
  switch (action.type) {
    case SHOW_SIDEBAR:
      return { ...state, sidebarShow: action.data };
    case MINIMIZE_SIDEBAR:
      return { ...state, sidebarMinimize: action.data };
    case SHOW_ASIDE:
      return { ...state, asideShow: action.data };
    case ENABLE_DARKMODE:
      return { ...state, darkMode: action.data };
    default:
      return state;
  }
};
