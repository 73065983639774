import { Reducer } from 'react';
import { Action } from '@nimles/react-redux';

export const ENABLE_GROSSPRICE = 'ENABLE_GROSSPRICE';
export const DISABLE_GROSSPRICE = 'DISABLE_GROSSPRICE';

export const enableGrossPrice = () => ({
  type: ENABLE_GROSSPRICE,
  data: true,
});

export const disableGrossPrice = () => ({
  type: DISABLE_GROSSPRICE,
  data: true,
});

export const grossPriceReducer: Reducer<boolean, Action> = (
  state = false,
  action: Action
) => {
  switch (action.type) {
    case ENABLE_GROSSPRICE:
      return true;
    case DISABLE_GROSSPRICE:
      return false;
    default:
      return state;
  }
};
