import { Provider } from 'react-redux';
import React, { FC } from 'react';
import { routerConfiguration } from '@nimles/react-web-components-redux';
import { configuration } from '@nimles/rest-api';
import { initStore } from './redux/init';
import { Link } from 'gatsby-plugin-react-i18next';

configuration.apiRoot = 'https://quotes.azurewebsites.net/api/nimles/';
configuration.identityRoot = 'https://quotes.azurewebsites.net/api/identity';

export const Initializer: FC = ({ children }) => {
  const store = initStore({
    tenantId: process.env.GATSBY_TENANT,
    clientId: process.env.GATSBY_CLIENT,
    localStorageName: process.env.GATSBY_NAME + 'redux',
  });

  routerConfiguration.linkComponent = Link;

  return <Provider store={store}>{children}</Provider>;
};
