import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: '97848c8a-ab20-4053-a680-83cf0f2d1ae4',
  },
});

export const onInitialClientRender = () => {
  appInsights.loadAppInsights();
};

export const onRouteUpdate = () => {
  appInsights.trackPageView();
};
